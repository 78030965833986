import * as utility from './_utility';

// dispatch custom event to resize carousel
const changeFontEvent = new CustomEvent('changefont', {
    bubbles: true,
});

function dispatchChangeFontEvent(el) {
    setTimeout(
	el.dispatchEvent(
	    changeFontEvent
	), 500
    )
}

const enchanceReadabilitySwitcherId = 'enhance-readability-switcher';
const themeSwitcherId = 'theme-switcher';

const SWITCH_MAP = {
    [enchanceReadabilitySwitcherId]: {
	true: function() {
	    utility.setCssVariable(
		'--font-family',
		utility.getCssVariable('--ff-enhance-readability')
	    );
	    utility.setCookie(
		enchanceReadabilitySwitcherId,
		true
	    );
	    dispatchChangeFontEvent(
		document.getElementById(enchanceReadabilitySwitcherId)
	    )
	},
	false: function() {
	    utility.setCssVariable(
		'--font-family',
		utility.getCssVariable('--ff-base')
	    );
	    utility.eraseCookie(enchanceReadabilitySwitcherId);
	    dispatchChangeFontEvent(
		document.getElementById(enchanceReadabilitySwitcherId)
	    )
	},
    },
    [themeSwitcherId]: {
	true: function() {
	    utility.setCssVariable(
		'--color',
		utility.getCssVariable('--off-white')
	    );
	    utility.setCssVariable(
		'--background',
		utility.getCssVariable('--light-black')
	    );
	    utility.setCookie(
		themeSwitcherId,
		true
	    );
	},
	false: function() {
	    utility.setCssVariable(
		'--color',
		utility.getCssVariable('--light-black')
	    );
	    utility.setCssVariable(
		'--background',
		utility.getCssVariable('--off-white')
	    );
	    utility.eraseCookie(themeSwitcherId);
	},
    },
} 

function addAriaLiveToActiveCarouselElement(evt) {
    evt.target.querySelectorAll('.carousel-item').forEach(function(el) {
	el.removeAttribute('aria-live');
	el.removeAttribute('aria-atomic');
    });
    evt.relatedTarget.setAttribute('aria-live', 'polite');
    evt.relatedTarget.setAttribute('aria-atomic', 'true');
}

export {SWITCH_MAP, addAriaLiveToActiveCarouselElement}
