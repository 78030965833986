import * as bootstrap from 'bootstrap';
import * as a11y from './_accessibility';
import * as carousel from './_carousel';
import * as utils from './_utility';
import * as video from './_video';

// DOMContentLoaded
document.addEventListener('DOMContentLoaded', function () {
    // accessibility
    const readabilitySwitcher = document.getElementById(
        'enhance-readability-switcher'
    );

    const themeSwitcher = document.getElementById(
        'theme-switcher'
    )

    function switcherHandler(element) {
        a11y.SWITCH_MAP[element.id][element.checked]();
    }

    [
        readabilitySwitcher,
        themeSwitcher
    ].forEach(function (switcher) {
        switcher.addEventListener('change', function (evt) {
            switcherHandler.bind(
                this, evt.currentTarget
            )();
        });
    });

    // avoid dropdown closing when click on switch label
    const accessibilityMenu = document.getElementById('accessibility-menu');
    accessibilityMenu.addEventListener(
        'click',
        function (evt) {
            evt.stopPropagation()
        },
        true
    );
    // end accessibility

    // __init__
    for (const k in a11y.SWITCH_MAP) {
        if (document.cookie.includes(k)) {
            a11y.SWITCH_MAP[k][true]();
            document.getElementById(k).checked = true;
        }
    }
});

// carousel aria live
document.addEventListener('slid.bs.carousel', function (evt) {
    a11y.addAriaLiveToActiveCarouselElement(evt);
});

// carousel height and min-height
// window has rendered all contents
window.addEventListener('load', function (evt) {
    // carousel
    carousel.setHeightToCarousel(evt);
});

// change orientation
try {
    screen.orientation.addEventListener(
        'change', function (evt) {
            carousel.setHeightToCarousel(evt);
        }
    );
    // orientationchange is deprecated but works in firefox
    window.addEventListener(
        'orientationchange', function (evt) {
            carousel.setHeightToCarousel(evt);
        }
    );
} catch (e) {
    console.log(e);
}

// resize screen
window.addEventListener('resize', function (evt) {
    setTimeout(
        carousel.setHeightToCarousel.bind(this, evt),
        500
    );
});

// change font (OpenDyslexic has a wider size)
['changefont', 'slide.bs.carousel'].forEach(
    function (e) {
        document.addEventListener(
            e, function (evt) {
                carousel.setHeightToCarousel(evt)
            }, true
        )
    }
);
// end carousel height

// pause video if any is played when change carousel slide
document.addEventListener(
    'slide.bs.carousel', video.pauseCurrentlyPlayingVideo
);