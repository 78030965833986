// fixed height carousel
function getElementHeight(el) {
    // * in px

    let elHeight;
    
    // Ensure el has display != none

    el.style.setProperty('display', 'block');
    el.style.removeProperty('min-height');
    el.style.removeProperty('height');
    elHeight = el.offsetHeight;
    el.style.removeProperty('display');
    
    // if element has no inline css declaration, remove style attribute
    if (!el.style.length) {
	el.removeAttribute('style');
    }

    return elHeight;
}

function setStyleProperty(el, prop, val) {
    el.style.setProperty(prop, val);
}

// set
// {min-height: carousel-item max height}
// to carousel-items
// or set
// {height: carousel-item height}
// to carousel-inner
function setHeightToCarousel(evt, carouselSelector=null) {
    const carouselList = document.querySelectorAll(carouselSelector || '.carousel');

    if (carouselList.length) {
	carouselList.forEach(function(carousel) {
	    const carouselItems = carousel.querySelectorAll('.carousel-item');

	    // fixed height carousel with min-height
	    if (carousel.classList.contains('fixed-height')) {
		if (evt.type === 'slide.bs.carousel') {
		    return;
		}

		const allCarouselItemsHeight = Array.from(carouselItems).map(getElementHeight);

		// add a pixel to handle possible decimals
		const maxHeight = Math.max.apply(null, allCarouselItemsHeight) + 1;

		carouselItems.forEach(function(el) {
		    setStyleProperty(el, 'min-height', `${maxHeight}px`);
		});
	    } else {
		if (evt.type === 'slide.bs.carousel') {
		    setTimeout(function() {
			carousel.querySelector('.carousel-inner').style.setProperty(
			    'height', `${getElementHeight(evt.relatedTarget)}px`
			)
		    }, 100)
		    // 100 is a magic number that lets the target carousel item get the correct height
		} else {
		    carousel.querySelector('.carousel-inner').style.setProperty(
			'height', `${getElementHeight(carousel.querySelector('.carousel-item.active'))}px`
		    )
		}
	    }
	});
    }
}
// end fixed height carousel

export {setHeightToCarousel};
