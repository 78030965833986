// cookies
const isConnectionSecure = location.protocol === "https:";
const EXP_DAYS = 90;

function setCookie(name, value, days = EXP_DAYS) {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie =
    name +
    "=" +
    (value || "") +
    expires +
    `; path=/; sameSite=strict${isConnectionSecure ? "; secure=true" : ""}`;
  isConnectionSecure ? null : console.warn("The connection is not secure");
}

function readCookie(name) {
  const cookieName = `${name}=`;
  if (cookieExists(name)) {
    const cookieArray = document.cookie.split(";");
    for (const cookie of cookieArray) {
      const trimmedCookie = cookie.trim();
      if (trimmedCookie.indexOf(cookieName) === 0) {
        // return a string
        return trimmedCookie.substring(cookieName.length);
      }
    }
  } else {
    return null;
  }
}

function eraseCookie(name) {
  setCookie(name, "", -1);
}

function cookieExists(name) {
  return document.cookie.indexOf(name) !== -1;
}

// css vars
function setCssVariable(varName, varVal) {
    document.body.style.setProperty(varName, varVal);
}

function getCssVariable(varName) {
    return getComputedStyle(
	document.body
    ).getPropertyValue(varName);
}
// end css vars

export {setCookie, readCookie, eraseCookie, cookieExists, getCssVariable, setCssVariable}
